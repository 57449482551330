import React, { Component } from "react"
import { Link } from "react-scroll"

export default class NavBar extends Component {
  render() {
    const { menuItems, innerRef } = this.props
    return (
      <ul className="nav navbar__list" ref={innerRef}>
        {!!menuItems &&
          !!menuItems.nodes &&
          menuItems.nodes.map((menuItem, i) => {
            if (menuItem.parentId) {
              return null
            }
            //const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url;
            return (
              <li className="nav__item" key={i + menuItem.url}>
                <Link
                  to={(i + 1).toString()}
                  smooth
                  duration={1000}
                  className="nav__link"
                >
                  {menuItem.label}
                </Link>
              </li>
            )
          })}
      </ul>
    )
  }
}
