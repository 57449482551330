import React from "react"
import { Link } from "gatsby"
import AbigailsLogo from "../../assets/images/logo.png"

export default function Logo({ language }) {
  /**
   * @type {string}
   */
  const url = language === "en" ? "/" : "/" + language
  return (
    <Link className="logo" to={url}>
      <img alt="Abigails" src={AbigailsLogo} className="logo__img" />
    </Link>
  )
}
