import React, { Component } from "react"
import Phone from "../../assets/images/phone.svg"
import Email from "../../assets/images/mail.svg"

const contacts = [
  {
    img: Phone,
    text: "+ 44 74 4142 7530",
    link: "tel:00447441427530",
  },
  {
    img: Email,
    text: "manager@irabigails.com",
    link: "mailto:manager@irabigails.com",
  },
]

export default class ContactsItem extends Component {
  render() {
    return (
      <div>
        {!!contacts &&
          contacts.map((item, i) => {
            return (
              <div>
                <a
                  href={item.link}
                  rel="noreferrer"
                  className={"contacts__item flex mt-1"}
                  key={i + "_contact"}
                >
                  <div className="contacts__item__img mr-2">
                    <img
                      className="icon--sm w-40"
                      src={item.img}
                      alt={item.text}
                    />
                  </div>
                  {item.text}
                </a>
              </div>
            )
          })}
      </div>
    )
  }
}
