import React from "react"
import { Trans } from "gatsby-plugin-react-i18next"

const Footer = () => {
  return (
    <footer className="footer wrapper py-2 px-3">
      <Trans>footer.copyright</Trans>
      <span>&nbsp;© Abigails {new Date().getFullYear()}</span>
    </footer>
  )
}

export default Footer
