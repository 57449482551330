import React, { Component } from "react"
import { graphql, Link, StaticQuery } from "gatsby"

export default class LanguageSwitcher extends Component {
  static BuildLanguages(languages, defaultLanguage) {
    return languages.map(language => {
      return {
        name: language.name,
        code: language.code,
        slug: defaultLanguage.id === language.id ? "/" : language.slug, // Set no language path for the default language
      }
    })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query GetLanguages {
            wp {
              defaultLanguage {
                id
                code
                slug
              }
              languages {
                code
                id
                name
                slug
                locale
              }
            }
          }
        `}
        render={function (data) {
          const { languages, defaultLanguage } = data.wp

          return LanguageSwitcher.BuildLanguages(
            languages,
            defaultLanguage
          ).map((language, i) => {
            const path = language.slug === "/" ? "/" : "/" + language.slug
            return (
              <Link className={"language__lang"} to={path} key={"lang_" + i}>
                {language.code}
              </Link>
            )
          })
        }}
      />
    )
  }
}
