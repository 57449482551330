import React, { useEffect, useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import FormField from "./FormField"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

export default function ContactForm() {
  const { t } = useTranslation()
  const FORM_ID = 163 //Form id that provides Contact Form 7
  const {
    register,
    errors,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm()

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)
  const [isSubmitFailed, setIsSubmitFailed] = useState(false)

  // set timeout 3 seconds to remove error/success message.
  useEffect(() => {
    setTimeout(() => {
      setIsSubmitSuccessful(false)
    }, 3000)
  }, [isSubmitSuccessful])

  const onSubmit = async body => {
    if (!isSubmitting) {
      const bodyFormData = new FormData()

      bodyFormData.append("your-name", body.fullName)
      bodyFormData.append("your-email", body.email)
      bodyFormData.append("your-message", body.message)

      await axios({
        method: "post",
        url: `https://irabigails.com:1337/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
        data: bodyFormData,
      })
        .then(response => {
          if (response.data.status === "mail_sent") {
            setIsSubmitSuccessful(true)
            reset()
            return
          }

          setIsSubmitFailed(true)
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const data = {
    inputs: [
      {
        name: "fullName",
        placeholder: t("form.name"),
        required: true,
        errorMsg: "contact.form.required",
        validation: { required: true, min: 3 },
        disabled: isSubmitting,
      },
      {
        name: "email",
        placeholder: t("form.email"),
        required: true,
        errorMsg: "contact.form.email",
        validation: { required: true, pattern: /\S+@\S+\.\S+/i },
        disabled: isSubmitting,
      },
      {
        name: "message",
        placeholder: t("form.message"),
        required: true,
        errorMsg: "contact.form.required",
        validation: { required: true, maxLength: 220 },
        disabled: isSubmitting,
      },
    ],
  }
  const { inputs } = data

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="form"
      action=""
      method="post"
      id="contact-us"
    >
      {inputs.map((input, index) => {
        return (
          <div key={index} className="input-container">
            <FormField input={input} innerRef={register(input.validation)} />
            {errors[input.name] && (
              <span className="error-msg">
                <Trans>{input.errorMsg}</Trans>
              </span>
            )}
          </div>
        )
      })}
      <button className="cta-btn cta-btn--hero" type="submit">
        <Trans>submit</Trans>
      </button>
      {isSubmitSuccessful && (
        <div>
          <span className="error-msg">
            <Trans>contact.form.success</Trans>
          </span>
        </div>
      )}
      {isSubmitFailed && (
        <div>
          <span className="error-msg">
            <Trans>contact.form.error</Trans>
          </span>
        </div>
      )}
    </form>
  )
}
