import React from "react"

const FormField = data => {
  const { input, innerRef } = data
  return (
    <>
      {input.name !== "message" ? (
        <>
          <input
            className={"form__input" + (input.errors ? " error" : "")}
            type="text"
            name={input.name}
            required={input.required === true && true}
            ref={innerRef}
            placeholder={input.placeholder}
          />
        </>
      ) : null}
      {input.name === "message" ? (
        <>
          <textarea
            className={
              "form__input form__textarea" + (input.errors ? " error" : "")
            }
            required
            name={"message"}
            ref={innerRef}
            placeholder={input.placeholder}
          />
        </>
      ) : null}
    </>
  )
}

export default FormField
