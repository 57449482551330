import React from "react"
import ContactsItem from "./Contacts-item"
import Form from "../Form/Form"
import { Trans } from "gatsby-plugin-react-i18next"

const Contacts = () => {
  return (
    <div className="contacts container" id="5">
      <div className="row">
        <div className="col-lg-12">
          <h1 className="text-center mb-5">
            <Trans>contact.form.title</Trans>
          </h1>
        </div>
        <div className="col-lg-2 col-md-12" />
        <div className="col-lg-4 col-md-6">
          <div className="contacts__phones">
            <ContactsItem />
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <Form />
        </div>
        <div className="col-lg-2 col-md-12" />
      </div>
    </div>
  )
}

export default Contacts
