import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import Footer from "./footer"
import Header from "./header"
import favicon from "../assets/images/favicon.ico"
import "../assets/style.scss"
import Contacts from "./Contacts/Contacts"
import Cookies from "./Cookies/Cookies"

const Layout = ({ children, language, wrongPage }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)
  return (
    <div className={`wrapper ${wrongPage ? "page-not-found" : ""}`}>
      <Helmet>
        <link rel="icon" href={favicon} />
        <script type="text/javascript">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NXCTD6F');`}
        </script>
      </Helmet>
      <Header title={title} language={language} />
      <main>{children}</main>
      {wrongPage ? null : <Contacts />}
      <Footer />
      {wrongPage ? null : <Cookies />}
    </div>
  )
}

export default Layout
