import React, { useState, useEffect, useRef } from "react"
import NavBar from "./NavBar/NavBar"
import Logo from "./Logo/Logo"
import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher"
import ContactsItem from "./Contacts/Contacts-item"
import { graphql, useStaticQuery } from "gatsby"

export default function Header({ language }) {
  const { ref, isVisible, setVisible } = useComponentVisible(false)
  const menuIdentifier =
    language === "en" ? "PRIMARY" : "PRIMARY___" + language?.toUpperCase()
  const { allWpMenu } = useStaticQuery(graphql`
    {
      allWpMenu {
        nodes {
          name
          locations
          menuItems {
            nodes {
              label
              url
              parentId
            }
          }
        }
      }
    }
  `)

  const wpMenu = allWpMenu.nodes
    .filter(
      menu =>
        menu.locations.filter(location => location === menuIdentifier).length >
        0
    )
    .shift()
  return (
    <div className={`header ${isVisible ? "active" : ""}`}>
      <div className="flex align-center">
        <Logo language={language} />
        <ContactsItem />
      </div>
      <div className="language language--lg">
        <LanguageSwitcher />
      </div>
      <li
        className={`bars navbar-toggler ${isVisible ? " open" : ""}`}
        onClick={() => setVisible(!isVisible)}
        aria-hidden="true"
      >
        <div className="bar1" />
        <div className="bar2" />
        <div className="bar3" />
      </li>
      <nav className={`navbar${isVisible ? " active" : ""}`}>
        <NavBar innerRef={ref} menuItems={wpMenu?.menuItems} />
        <div className="language language--sm">
          <LanguageSwitcher />
        </div>
      </nav>
    </div>
  )
}

function useComponentVisible(initialIsVisible) {
  const [isVisible, setVisible] = useState(initialIsVisible)
  const ref = useRef(null)
  const handleHideDropdown = event => {
    if (event.key === "Escape") {
      setVisible(false)
    }
  }

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.addEventListener("keydown", handleHideDropdown, true)

      return () => {
        document.removeEventListener("keydown", handleHideDropdown, true)
      }
    }
  })

  return { ref, isVisible, setVisible }
}
