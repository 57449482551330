import React from "react"
import { Trans } from "gatsby-plugin-react-i18next"
import CookieConsent from "react-cookie-consent"

export default function Cookies() {
  return (
    <CookieConsent
      location="bottom"
      buttonText={<Trans>cookies.button</Trans>}
      cookieName="irabigails.com"
      containerClasses={"cookies rounded shadow-lg"}
      buttonClasses={"cookies__btn"}
      expires={150}
    >
      <Trans>cookies</Trans>
    </CookieConsent>
  )
}
